import { useState } from 'react'
import styled from 'styled-components'

import media from '../utils/media'

import PlusIcon from '../assets/svg/plus.svg'
import QuestionMarkIcon from '../assets/svg/question-mark.svg'

import PageSectionFAQAnswer from './PageSectionFAQAnswer'

const Container = styled.section`
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  max-width: calc(min(100vw, var(--max-width)) - var(--spacing-md) * 2);
  margin: auto;

  ${media.md`
    flex-direction: row;
  `}
`

const Sidebar = styled.aside`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  gap: var(--spacing-md);
  align-items: center;

  ${media.md`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const Content = styled.div`
  flex-basis: 70%;
`

const QuestionMark = styled(QuestionMarkIcon)`
  flex: 0 0 auto;
`

const Title = styled.h2`
  font-size: 175%;
  line-height: 1;

  margin: 0;

  ${media.sm`
    font-size: 200%;
  `}

  ${media.md`
    font-size: 250%;
  `}
`

const Questions = styled.ol`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: var(--spacing-md);
  list-style: none;
`

const QuestionContainer = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: var(--spacing-md);
  background-color: var(--color-background-light-2);
  border-radius: var(--border-radius-large);
  transition: all 0.3s ease-out;

  padding: var(--spacing-md);
`

const Question = styled.h3`
  cursor: pointer;
  font-size: 125%;
  line-height: 1;

  margin: 0;

  ${media.sm`
    font-size: 150%;
  `}
`

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Plus = styled.span`
  cursor: pointer;
  transform: ${({ $active }) => ($active ? 'rotate(45deg)' : 'none')};
  transition: all 0.3s ease-out;

  height: 22px;
  width: 22px;

  svg {
    transform: scale(0.7);

    & > g > g {
      stroke: ${({ $active }) => ($active ? 'black' : 'var(--color-grey)')};
    }
  }
`

const Faq = ({ title, faqs }) => {
  const [currentIds, setCurrentIds] = useState(faqs.map((faq) => faq.id))

  const toggleActive = (id) => {
    if (currentIds.includes(id)) {
      setCurrentIds(currentIds.filter((activeId) => activeId !== id))
    } else {
      setCurrentIds([id, ...currentIds])
    }
  }

  return (
    <Container>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: faqs.map((faq) => ({
              '@type': 'Question',
              name: faq.question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: faq.answer.answer,
              },
            })),
          }),
        }}
      />

      <Sidebar>
        <QuestionMark />
        <Title>{title}</Title>
      </Sidebar>

      <Content>
        <Questions>
          {faqs.map((faq) => (
            <QuestionContainer key={faq.id}>
              <QuestionHeader>
                <Question onClick={() => toggleActive(faq.id)}>{faq.question}</Question>

                <Plus
                  $active={currentIds.includes(faq.id)}
                  onClick={() => toggleActive(faq.id)}>
                  <PlusIcon />
                </Plus>
              </QuestionHeader>

              <PageSectionFAQAnswer
                active={currentIds.includes(faq.id)}
                answer={faq.answer}
              />
            </QuestionContainer>
          ))}
        </Questions>
      </Content>
    </Container>
  )
}

export default Faq
