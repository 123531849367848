import styled from 'styled-components'

const Answer = styled.div`
  p,
  li {
    opacity: ${({ $active }) => ($active ? '1' : '0')};
    color: var(--color-typography-dark);
  }

  & > p:not(:last-child),
  & > li:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }

  display: ${({ $active }) => ($active ? 'block' : 'none')};
  transition:
    opacity 0.3s ease-out,
    max-height 0.4s ease-out;
`

const PageSectionFAQAnswer = ({
  active,
  answer: {
    childMarkdownRemark: { html: answerHtml },
  },
}) => {
  return (
    <Answer
      $active={active}
      dangerouslySetInnerHTML={{
        __html: answerHtml,
      }}
    />
  )
}

export default PageSectionFAQAnswer
